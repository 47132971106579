import React from 'react';
import './FloatingButton.css';
import { IoMenuSharp } from "react-icons/io5";
import MainComponent from '../MainComponent';
// import { ReactComponent as MenuIcon } from './myicon/menu_icon.svg';
import { ReactComponent as MenuIcon } from '../myicon/mainmenu_icon.svg';


  

const FloatingButton = ({ style, iconMenuClick }) => {


    const handleIconClick = (iconId) => {
        
        console.log('Даа!' + iconId)
        iconMenuClick(iconId);
    };
    
    return (
        <button className="floating-button" onClick={() => handleIconClick('isMainMenu')}>
         < MenuIcon />
        </button>
    );
};

export default FloatingButton;