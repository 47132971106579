import React, { useState, useEffect, useRef } from 'react';

import { AiOutlineClose, AiOutlineStar, AiFillStar } from "react-icons/ai";
import TextField from '@mui/material/TextField';
import { IconButton , InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import { CiSearch } from "react-icons/ci";
import { CiStar } from "react-icons/ci";
import { HiOutlineX } from "react-icons/hi";
import { fetchDataFromDatabase } from './DataService';
import FloatingButton from './Button/FloatingButton';


let productID = [];

const sentence = (string) => {
  return `В 1г - ` + string + 'Ккал';
};

const ProductMassCal = (num1, num2) => {
if (num2 !=null) {
  const result = parseFloat(num1)*parseFloat(num2);
return 'В ' + num2 + 'г - '+ result.toFixed(2) + ' Ккал'
}
return ''
};
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const maincontainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  color: 'black',
  paddingTop: '20px',
  height: '100%',
  // border: '1px solid #ccc'
};
const containerStyles = {
  display: 'flex',
    flexDirection: 'column', // Вертикальное расположение
    gap: '10px',
};
const informationStyles = {
  flex: 1,
  fontSize: '14px',
  paddingTop: '15px',
  // border: '1px solid #ccc'
  
};
const inputStyles = {
  flex: 1,
  padding: '10px 20px 5px',
  fontSize: '12px',
  borderRadius: '10px',
  marginLeft: '5px',
  marginRight: '5px',
};
const containerItem = {
  flex: 1,
  flexDirection: 'column',
  padding: '10px 20px 5px',
  marginBottom: '50px',
};
const itemStyle = {
  flex: 1,
  padding: '5px 0',
  alignSelf: 'flex-start',
  
};
const itemNameStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  // border: '1px solid #ccc'
};
const itemNameStyleText = {
  display: 'flex',
  flex: 1,
  fontSize: '14px',
  // fontWeight: 'bold',
  // border: '1px solid #ccc'
};
const itemNameStyleIcon = {
  display: 'flex',
  // border: '1px solid #ccc'
};
const itemCalorieStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontStyle: 'italic',
  // border: '1px solid #ccc'
};
const itemCalorieMStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 'bold'
  // border: '1px solid #ccc'
};

const StyleFooter = {
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'center',
  justifyContent: 'space-around',
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  backgroundColor: 'white',
  // padding: '3px',
  border: '1px solid #ccc',
  
  // marginLeft: '1px'
};


const CustomTextField = styled(TextField)({
  '& .MuiFormHelperText-root': {
      color: 'red', // Применение красного цвета к тексту подсказки
      fontSize: '12px', // Изменение размера шрифта подсказки
      fontStyle: 'italic', // Применение курсива к тексту подсказки
      paddingTop: '5px',
      width: '100%',
  },
});
const CustomTextFieldMassa = styled(TextField)({
  '& .MuiFormHelperText-root': {
      // color: 'red', // Применение красного цвета к тексту подсказки
      fontSize: '14px', // Изменение размера шрифта подсказки
      fontWeight: 'bold', // Применение курсива к тексту подсказки
      paddingTop: '20px',
      
  },
});


const ExampleComponent = ({id_user, iconMenuClick}) => {
    const [data, setData] = useState([])
    const [inputValue, setInputValue] = useState('');
    const [inputValueMassa, setInputValueMassa] = useState('');
    const [Mass, setMass] = useState(0);
    const [starredItems, setStarredItems] = useState(data.map(() => false));
    const [isPlaceholder, setPlaceholder] = useState('масса в граммах');
    const [isInformationText, setInformationText] = useState('Избранные продукты');
    
    useEffect(() => {
        setInputValue('');
        setData([]);
        setMass(0);
        setInformationText('Найденные продукты');
        setPlaceholder('продукт/блюдо');
    }, []);
  
    
  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = 'https://www.foodandsports.ru/api/favoritesproductsgetalluserid.php'; 
        const jsonData = JSON.stringify({ id_user: id_user });
        const favoriteProductsData = await fetchDataFromDatabase(url, jsonData);
        const productID = favoriteProductsData.map(product => product.id_productfood);
        const newStarredItems = data.map(item => productID.includes(item.id));
        setStarredItems(newStarredItems);
        console.log(productID); 

      } catch (error) {
        console.error(error);
    }
    };
    fetchData(); 
  }, [data]);
  
  const handleStarClick = async (index) => {
  const newStarredItems = [...starredItems];
  newStarredItems[index] = !newStarredItems[index];
  setStarredItems(newStarredItems);
  
      // Отправка данных на сервер через API
      try {

        if (newStarredItems[index]) { // Добавление продукта в избранные
            const url = 'https://www.foodandsports.ru/api/favoritesproductsadd.php'; 
            const jsonData = JSON.stringify({ id_food: data[index].id, id_user: id_user});
            await fetchDataFromDatabase(url, jsonData);
        }

        if (!newStarredItems[index]) { // Удаление продукта в избранного
          console.log("555");
            const url = 'https://www.foodandsports.ru/api/favoritesproductsdel.php'; 
            const jsonData = JSON.stringify({ id_food: data[index].id, id_user: id_user});
            await fetchDataFromDatabase(url, jsonData);
       }
      } catch (error) {
          console.error('Произошла ошибка:', error);
      }  
  };

  const handleInputChangeMassa = async (event) => {
    const value_massa = event.target.value;
    if (/^\d*$/.test(value_massa)) {
      setInputValueMassa(value_massa);
      setMass(value_massa);
    }
  }
    const handleInputChange = async (event) => {
      const value = event.target.value;

      if (value === '') {
        clearInput();
        return;
      }
          console.log('Да!')
          setInputValue(value); 
        console.log('value - ' + value)

        try {
          const url = `https://www.foodandsports.ru/api/search_product.php?term=${value}`;
          const response = await fetch(url);
          const jsonData = await response.json();
          setData(jsonData);

      } catch (error) {
        console.error('Error fetching meal data:', error);
        }
    };
    const clearInput = () => {
        setInputValue('');

    };

    const clearInputMassa = () => {
      setInputValueMassa('');
      setMass(0);
    }
  
  
  const handleItemClick = async (index) => {
   
  };
  
  const handleIconMenuClick = (iconId) => {
    console.log('clicked icon:', iconId);
    iconMenuClick(iconId);
   
  };


return (
  <div className="app-container">
    
    <div className="header">

 <CustomTextField
      style={inputStyles} 
      value={inputValue} 
      // helperText={isHelperText}
      onChange={handleInputChange} 
      placeholder={isPlaceholder}
      InputProps={{
          endAdornment: (
            <React.Fragment>
                <IconButton onClick={clearInput}>
                  <HiOutlineX />
                </IconButton>               
            </React.Fragment>  
          )
      }}
  />
    <CustomTextFieldMassa
        style={inputStyles} 
        value={inputValueMassa} 
        // helperText={isHelperText}
        onChange={handleInputChangeMassa} 
        placeholder={'масса продукта/блюда в граммах'}
        InputProps={{
            endAdornment: (
                <React.Fragment>
                    <IconButton onClick={clearInputMassa}>
                        <HiOutlineX />
                    </IconButton>
                </React.Fragment>  
            )
        }}
    />  
</div>



<div style={informationStyles}>{isInformationText}</div>
<ul style={containerItem}>
  {data.map((item, index) => (
   <div key={index} style={itemStyle} onClick={() => handleItemClick(index)}>
    <div style={itemNameStyle}>
      <div style={itemNameStyleText}>
        {capitalizeFirstLetter(item.name)}
      </div>
      <div style={itemNameStyleIcon} onClick={(e) => {
        e.stopPropagation(); // Остановить всплытие события
           if (!e.target.closest('.itemNameStyleIcon')) {
             handleStarClick(index);
              }
       }}>
     
      {starredItems[index] ? <AiFillStar /> : <AiOutlineStar />}
      
      </div>   
    </div>
    <div style={itemCalorieStyle} >
      <div>{sentence(item.calorie)}</div>
    </div>
    <div style={itemCalorieMStyle}>
      <div>{Mass !== 0 && ProductMassCal(item.calorie, Mass)}</div>
    </div>
   </div>
  ))}
</ul>

{/* <h1>Даа!</h1> */}
<FloatingButton iconMenuClick={handleIconMenuClick}/>

</div>
);
};
export default ExampleComponent;