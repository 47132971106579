const fetchDataFromDatabase = async (url, jsonData) => {
 
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: jsonData
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      // Обработка полученных данных, если это необходимо
      
      return data;
  
    } catch (error) {
      console.error('Error:', error);
      // Дополнительная обработка ошибки
    }
  };
  export { fetchDataFromDatabase };