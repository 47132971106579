import React from 'react';
import SearchComponent from './SearchComponent';
import MainComponent from './MainComponent';

function Caloriefoods() {
  return (
    <div>
     {/* <SearchComponent /> */}
     <MainComponent />
     
    </div>
  );
}

export default Caloriefoods;