import React, { useState, useEffect, useRef } from 'react';
import { BsInfoLg } from "react-icons/bs";
import { CiStar } from "react-icons/ci";
import { CiSearch } from "react-icons/ci";
import { BsPlusLg } from "react-icons/bs";
import { BsReverseLayoutTextSidebarReverse } from "react-icons/bs";
import { MdOutlineSportsHandball } from "react-icons/md";
import { MdSportsTennis } from "react-icons/md";
import { PiBowlFoodLight } from "react-icons/pi";
import { FiUser } from "react-icons/fi";
import { LuUtensils } from "react-icons/lu";
import { MdOutlineSoupKitchen } from "react-icons/md";
import { PiStarBold } from "react-icons/pi";
import { ReactComponent as FoodIcon } from './myicon/food.svg';
import { ReactComponent as StarIcon } from './myicon/star_icon.svg';
import { ReactComponent as SearchIcon } from './myicon/search_icon.svg';
import { ReactComponent as LunchtimeIcon } from './myicon/lunchtime_icon.svg';
import { ReactComponent as CookingIcon } from './myicon/cooking_icon.svg';
import { ReactComponent as CookingTimeIcon } from './myicon/cookingtime_icon.svg';
import { ReactComponent as MealIcon } from './myicon/meal_icon.svg';
import { ReactComponent as SportIcon } from './myicon/sport_icon.svg';
import { ReactComponent as SportTrainingIcon } from './myicon/sportstraining_icon.svg';
import { ReactComponent as AddSportTrainingIcon } from './myicon/addsportstraining_icon.svg';
import { ReactComponent as UserIcon } from './myicon/user_icon.svg';
import { ReactComponent as MealplanningIcon } from './myicon/mealplanning_icon.svg';







function MainMenu({ iconClick }) {

  // const [isIconId, setIsIconId] = useState(null);


  const grid_container= {
    display: 'flex',
    justifycontent: 'center',
    paddingTop:'15px',
    backgroundColor: 'white',
    height: '100%', // Высота — вся видимая область
    // width: '100%', // Ширина — вся доступная ширина
    // boxSizing: 'border-box', // Чтобы учесть padding и другие отступы
   
    
    // border: '1px solid #ccc'
  };

  const grid_item = {
    flex: 1,
    textalign: 'center',
    marginBottom: '0',
    paddingBottom: '0',
    marginTop: '0',
    paddingTop: '0',


    // fontsize: '1rem',
    // padding:'10px',
    // border: '1px solid #ccc'

  };

  const iconStyle = {
    
    display: 'flex', 
    flexDirection: 'column', 
    alignItems: 'center',
    // fontsize: '24px',
    // border: '1px solid #ccc',
    // fontSize: '1rem',
  };

  const iconStyleText = {
    
    marginTop: '3px', 
    fontSize: '10px',
    lineHeight: '0.5',
    // border: '1px solid #ccc',
  };

  const handleIconClick = (iconId) => {
    iconClick(iconId);
  };

  return (
    <div>
       <div style={grid_container}>
      <div style={grid_item}>
        <i onClick={() => handleIconClick('')}>
          1
        </i>
      </div>
      <div style={grid_item}>
        <i className="fas fa-envelope">2</i>
      </div>
      <div style={grid_item}>
        <i className="fas fa-phone">3</i>
      </div>
      <div style={grid_item}>
        <i className="fas fa-phone">4</i>
      </div>
    </div>

    <div style={grid_container}>
      <div style={grid_item}>
      <i onClick={() => handleIconClick('isFavoriProducts')}>
          <div style={iconStyle}><StarIcon /></div>
          <span style={iconStyleText}>Избранные продукты/блюда</span>
      </i>
      </div>
      <div style={grid_item}>
      <i onClick={() => handleIconClick('isSearchProducts')}>
          <div style={iconStyle}><SearchIcon /></div>
          <span style={iconStyleText}>Справочник продуктов/блюд</span>
        </i>
      </div>
      <div style={grid_item}>
      <i>
          <div style={iconStyle}><CookingIcon/></div>
          <span style={iconStyleText}>Мои блюда</span>
      </i>
      </div>
      <div style={grid_item}>
      <i>
          <div style={iconStyle}><CookingTimeIcon /></div>
          <span style={iconStyleText}>Создать блюдо</span>
      </i>
      </div>
    </div>

    <div style={grid_container}>
      <div style={grid_item}>
      <i onClick={() => handleIconClick('isMeal')}>
          <div style={iconStyle}><LunchtimeIcon /></div>
          <span style={iconStyleText}>Приемы пищи</span>
      </i>
      </div>
      <div style={grid_item}>
      <i>
          <div style={iconStyle}><MealIcon /></div>
          <span style={iconStyleText}>Создать прием пищи</span>
      </i>
      </div>
      <div style={grid_item}>
      <i>
          <div style={iconStyle}><MealplanningIcon /></div>
          <span style={iconStyleText}>Запланировать приемы пищи</span>
      </i>
      </div>
      <div style={grid_item}>
        <i className="fas fa-phone"></i>
      </div>
    </div>
    
    <div style={grid_container}>
      <div style={grid_item}>
      <i>
          <div style={iconStyle}><SportIcon /></div>
          <span style={iconStyleText}>Спорт</span>
      </i>
      </div>
      <div style={grid_item}>
      <i>
          <div style={iconStyle}><SportTrainingIcon /></div>
          <span style={iconStyleText}>Тренировки</span>
      </i>
      </div>
      <div style={grid_item}>
      <i>
          <div style={iconStyle}><AddSportTrainingIcon /></div>
          <span style={iconStyleText}>Добавить тренировку</span>
      </i>
      </div>
      <div style={grid_item}>
        <i className="fas fa-phone"></i>
      </div>
    </div>

    <div style={grid_container}>
      <div style={grid_item}>
      <i onClick={() => handleIconClick('')}>
      <div style={iconStyle} ><UserIcon /></div>
          <span style={iconStyleText}>Личный кабинет</span>
        </i>
      </div>
      <div style={grid_item}>
      <i onClick={() => handleIconClick('')}>
          {/* <div style={iconStyle} ><UserIcon /></div>
          <span style={iconStyleText}>Личный кабинет</span> */}
        </i>
      </div>
      <div style={grid_item}>
        <i className="fas fa-phone"></i>
      </div>
      <div style={grid_item}>
        <i className="fas fa-phone"></i>
      </div>
    </div>
        
    </div>
    
);


}

export default MainMenu;