import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Router from './Router'



function App() {
  return (
    <div className="App">
  <Router />
    </div>
  );
}

export default App;
