import React, { useState, useEffect, useRef } from 'react';
import './FoodIntake.css'; // Стили для компонента
import FoodSelection from './FoodSelection';
import { ReactComponent as MinusIcon } from './myicon/minus_icon.svg';
import { fetchDataFromDatabase } from './DataService';
import { ReactComponent as BackArrow } from './myicon/left_icon.svg';
import { ReactComponent as PlusIcon } from './myicon/plus_icon.svg';
import './Button/Button.css';
import './FoodIntake.css';


const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const currentDate = new Date().toISOString().split('T')[0];

const FoodIntake = ({id_user,iconMenuClick,isMealItemEdit,dataEdit,onClick}) => {
    const [data, setData] = useState([])

    const [inputValue, setInputValue] = useState('Перекус');
    const [inputValueDate, setInputValueDate] = useState('');
    const [isMeal, setIsMeal] = useState('true');
    const [isMealSearchProduct, setIsMealSearchProduct] = useState('');
    const [isTime, setIsTime] = useState('');
    const [isDate, setIsDate] =  useState(currentDate);
    const [isTimeText, setIsTimeText] = useState('');
    const [products, setProducts] = useState([]);
    const [MassaProduct, setMassaProduct] = useState(0);
    const [isIdMeal, setIsIdMeal] = useState(0);
    const [isTypeMeal, setIsTypeMeal] = useState('Перекус');
    const [SumCaloria, setSumCaloria] = useState(0);
    const [MealSave, setMealSave] = useState(false);
    const [Exit, setExit] = useState(false);

    const TextHeader = 'Прием создание/редактирование';




  useEffect(() => {
    if (isMealItemEdit) {
    console.log('dataEditь-->',dataEdit)
    setIsIdMeal(dataEdit.id)
    }
}, []);

    useEffect(() => {
      console.log('data изменилась-->',data)
  }, [data]);

  useEffect(() => {
    if (!isIdMeal) return;
    iconMenuClick('MealCansel');

}, [Exit]);



    useEffect(() => {  
      
    if (isMealItemEdit) {
     
      setData([]);

      const fetchData = async () => {
      try {
          const editProductMealUrl = 'https://www.foodandsports.ru/api/mealGetId.php';
          const jsonData = JSON.stringify({ id_meal: dataEdit.id});
          const d = await fetchDataFromDatabase(editProductMealUrl , jsonData);
          // setProductsEdit(d);
          // console.log('d -->',d);
          fetchDataTwo(d);
      } catch(error) {
          console.error(error);
      }
      };

      const fetchDataTwo = async (d) => {
          try {
              const editProductslUrl = 'https://www.foodandsports.ru/api/productGetId.php';
              for (const item of d) {
                  const jsonData = JSON.stringify({ id_productfood: item.id_productfood});
                  const p = await fetchDataFromDatabase(editProductslUrl  , jsonData);
                  const updatedData = {...p[0],massa:item.massa};
                  // console.log('updatedData ->',updatedData);
                  setMassaProduct(item.massa);
                  setData((prevData) => [...prevData, updatedData]);  
              };

          } catch(error) {
          }

      };
    fetchData();
  }
},[] );


    useEffect(() => {

      if (isMealItemEdit) {
        setIsTime(dataEdit.time);
        const text = 'Время ' + dataEdit.time;
        setIsTimeText(text);
        setSumCaloria(parseFloat(dataEdit.calorie));

      } else {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const formattedTime = `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
        setIsTime(formattedTime);
        const text = 'Время ' + formattedTime;
        setIsTimeText(text);
      }
  }, []);


  useEffect(() => {
    if (!isMealItemEdit) {
    if (!isIdMeal) return;
    const fetchData = async () => {
      const addProductUrl = 'https://www.foodandsports.ru/api/addProductToMealApi.php';
      for (const item of data) {
        console.log('Добавление продукта')
        const jsonData = JSON.stringify({ id_meal: isIdMeal.id, id_productfood: item.id, massa: item.massa });
      try {
        await fetchDataFromDatabase(addProductUrl, jsonData);
      } catch (error) {
        console.error(error);
      }
    };
    iconMenuClick('MealCansel');

  };
    fetchData();
}
  }, [isIdMeal]);
  

  const addProduct = (id_productfood, massa) => {
    const newProduct = { id_productfood, massa }; // Создаем новый объект продукта
    setProducts([...products, newProduct]); // Добавляем новый продукт в массив
    };

  const handleInputChange = (event) => {
      setInputValue(event.target.value);
      setIsTypeMeal(event.target.value);
    };

    const handleInputChangeDate = (event) => {
      setInputValueDate(event.target.value);
      // setIsTypeMeal(event.target.value);
    };

  
  

    const handleAddMealClick = () => {
      console.log('Ура!7');
      console.log(data);
      setData(data);
      setIsMeal(prevIsMeal => !prevIsMeal);
      setIsMealSearchProduct(prevIsMealSearchProduct => !prevIsMealSearchProduct);
      // console.log(isMeal);
      // console.log(isMealSearchProduct);
    };

    const onAddProduct = (data1, massa) => {
      const updatedData1 = {...data1,massa:massa};
      setData((prevData) => [...prevData, updatedData1]);
      const calorie = data1.calorie * massa;
      setMassaProduct(massa);
      setSumCaloria((prevSum) => prevSum + calorie);
      
    };


  const handleMinusIconClick = (index) => {
    console.log('Иконка минус нажата на элементе с индексом:', index);
    const newData = data.filter((item, i) => i !== index);
    setData(newData);
    console.log('444', newData);

    const totalSum = newData.reduce((sum, item) => {
      return sum + (item.calorie * item.massa);
    }, 0);

    setSumCaloria(totalSum);
    console.log('Сумма калорий', totalSum);
  };




const handleBackButtonClick = async () => {
  console.log('Кнопка "Назад" нажата!');
  console.log('Данные сохранены!');
  console.log('id_user',id_user);
 
  if (isMealItemEdit) {
    try {
      const url_del = 'https://www.foodandsports.ru/api/meallistDelApi.php';
      const jsonData = JSON.stringify({ id_meal: isIdMeal});
      await fetchDataFromDatabase(url_del, jsonData);
      console.log ('Мы тут. Удалили записи продуктов', isIdMeal)
      console.log ('data -->', data);
  
      const addProductUrl = 'https://www.foodandsports.ru/api/addProductToMealApi.php';
      for (const item of data) {
      const jsonData = JSON.stringify({ id_meal: isIdMeal, id_productfood: item.id, massa: item.massa });
      try {
        await fetchDataFromDatabase(addProductUrl, jsonData);
      } catch (error) {
        console.error(error);
      }
    };
    } catch (error) {
        console.error(error);
    }
    setExit(true)

  } else {
    
    try {
      console.log('Добавление meal')
      const url = 'https://www.foodandsports.ru/api/mealEntryApi.php'; 
      const jsonData = JSON.stringify({ id_user: id_user, date:isDate, time:isTime, type:isTypeMeal, calorie:SumCaloria.toFixed(2) });
      const id_meal = await fetchDataFromDatabase(url, jsonData);
      setIsIdMeal(id_meal);

    } catch (error) {
      console.error(error);
    }
  }
};



  return (
    isMeal ? ( 
    
    <div className="app-container-fi">
      <header-fi>
      {TextHeader}
         <h5>Дата</h5>
         <input
                 type="text"
                 value={inputValueDate}
                 className="inputStyle-fi"
                 onChange={handleInputChangeDate}
                 placeholder={isDate}
             />
        
         <div>{isTimeText}</div>
         <div>{isDate}</div>
       
         <div>Сумарна калорийность - {SumCaloria.toFixed(2)} Ккал</div>
         <div>
             <input
                 type="text"
                value={inputValue}
                 className="inputStyle-fi"
                 onChange={handleInputChange}
                 placeholder="Введите название приема пищи"
             />
          
         </div>
      </header-fi>

      <main-fi>
         <div className="main-fi">
         <div className="main-container-fi">
           <div>
           <ul className="containerItem-fi">
                 {data.map((data, index) => (
                     <li key={index} className="itemStyle-fi">
                       <div className="itemNameStyleText-fi">
                           {capitalizeFirstLetter(data.name) + ' '}
                           <br />
                           Масса {data.massa}г - {(data.calorie * data.massa).toFixed(2)} Ккал
                           <div className="iconStyle-fi" onClick={() => handleMinusIconClick(index)}>
                             <MinusIcon />
                         </div>
                       </div>
                     </li>
                 ))}
             </ul>

           </div>
          
         </div>
       </div>
      </main-fi>
      <footer-fi>
        <div className="button-container">
              <button className="round-button center-button" onClick={handleAddMealClick}><PlusIcon/></button>
             <button className="round-button-not-border right-button" onClick={handleBackButtonClick}><BackArrow/></button>
        </div>
      </footer-fi>  

    </div>
    ) : isMealSearchProduct ? (
      <div>
        <FoodSelection iconMenuClick={handleAddMealClick} onAddProducts={onAddProduct}/>
      </div>
    ) : (
      <div></div>
    )
  );
};

export default FoodIntake;