import React from 'react';
import './SplashScreen.css'; // Импортируем файл стилей

const SplashScreen = () => {
  return (
    <div className="centered-text-container"> 
      <h1>Food&Sport</h1> {/* Отображаемый текст */}
    </div>
  );
}

export default SplashScreen;