import React from 'react';
import './InfoModal.css'; // Подключаем файл стилей для модального окна

const InfoModal = ({ isOpen, onClose, title, children }) => {
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h3>{title}</h3>
                {children}
            </div>
        </div>
    );
};

export default InfoModal;