import React, { useState, useEffect, useRef } from 'react';
// import Button from './Button/ButtonSave';
import { fetchDataFromDatabase } from './DataService';
// import FloatingButton from './Button/FloatingButton';
// import FloatingButtonTwo from './Button/FloatingButtonTwo';
import MealItem from './MealItem';
import { ReactComponent as BackArrow } from './myicon/left_icon.svg';
import { ReactComponent as PlusIcon } from './myicon/plus_icon.svg';
import './Button/Button.css';
import './MealsList.css';


// const liStyle = {
//     display: 'flex', 
//     flex: 1, 
//     justifyContent: 'center',
// };



function MealsList({ MealsListBack, id_user, iconMenuClick}) {
    const [data, setData] = useState([])
    const [result, setResult] = useState([])
    const [expandedItems, setExpandedItems] = useState({});

    const TextHeader = 'Приемы пищи';
    const TextCalorie = 'Ккал';

    function DateToDayOfWeek({ specificDate }) {
        const date = new Date(specificDate); // Создаем объект даты
        const dayOfWeek = date.toLocaleDateString('ru-RU', { weekday: 'long' }); // Получаем день недели
        const capitalizedDayOfWeek = dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1);
    
        return (
            <div>
                {capitalizedDayOfWeek }&nbsp;
            </div>
        );
    }

    function formatDate(inputDate) {
        const dateParts = inputDate.split('-'); // Разбиваем строку на части по знаку "-"
        const year = dateParts[0].slice(2); // Берем последние две цифры года
        const month = dateParts[1];
        const day = dateParts[2];
    
        return `${day}.${month}.${year}`; // Возвращаем отформатированную дату
    }

    useEffect(() => {
        const fetchMealData = async () => {
            try {
                const url = 'https://www.foodandsports.ru/api/mealgetall.php';
                const jsonData = JSON.stringify({ id_user: id_user });
                const data = await fetchDataFromDatabase(url, jsonData);
              
                // Сначала отсортируем данные по дате и времени
                const sortedData = data.sort((a, b) => {
                    if (a.date !== b.date) {
                        return a.date.localeCompare(b.date);
                    }
                    return a.time.localeCompare(b.time);
                });
    
                // Группировка данных сначала по дате, затем по времени
                const groupedData = sortedData.reduce((acc, curr) => {
                    const { id, date, time, type, calorie } = curr;
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push({ id, time, type, calorie});
                    return acc;
                }, {});
    
                // Создание результирующего массива с добавлением суммы калорий
                const result = Object.keys(groupedData).map(date => {
                    const meals = groupedData[date];
                    const totalCaloriesSum = meals.reduce((sum, meal) => sum + parseFloat(meal.calorie), 0);// Подсчет суммы калорий
                    const totalCalories = parseFloat(totalCaloriesSum).toFixed(2);
    
                    return {
                        date,
                        totalCalories, // Добавляем сумму калорий
                        meals,
                    };
                });
    
                console.log(result);
                setResult(result);
    
            } catch (error) {
                console.error('Error fetching meal data:', error);
            }
        };
    
        fetchMealData();
    
    }, [id_user]);

    const handleIconMenuClick = (iconId) => {
        iconMenuClick(iconId);
      };

      const handleClick = (item) => {
        console.log(`Вы нажали на элемент с временем ${item.time} и калориями ${item.calorie}`);
        console.log('item)',item);
        iconMenuClick('MealItemEdit',item);
      };

     
      const toggleExpand = (index) => {
        setExpandedItems((prev) => ({
            ...prev,
            [index]: !prev[index]
        }));
    }

  return (

    <div className="app-container-mlist">

    <header-mlist>{TextHeader}</header-mlist>

    <main-mlist>
             {/* <div className="main-mlist"> */}
             {result.map((item, index) => (
                 <div key={index}>
                     <div className="left-aligned-mlist" onClick={() => toggleExpand(index)}>
                         <DateToDayOfWeek specificDate={item.date}/>{formatDate(item.date)+' '}
                     ({item.totalCalories} {TextCalorie})
                     </div>
                     {expandedItems[index] && (
                 <ul>
                      {item.meals.map((meal, i) => (
                          <li key={i} className="li-style-mlist" onClick={() => handleClick(meal)}>
                         <MealItem time={meal.time} type={meal.type} calorie={meal.calorie} />
                         </li>
                     ))}
                 </ul>
              )}
                 </div>
             ))}
         {/* </div> */}
      </main-mlist>
      <footer-mlist>
        <div className="button-container">
             <button className="round-button center-button" onClick={()=>iconMenuClick('isMealAdd')}><PlusIcon/></button>
             <button className="round-button-not-border right-button" onClick={()=>iconMenuClick('isMainMenu')}><BackArrow/></button>
        </div> 
      </footer-mlist>
    </div>
    
);
}
export default MealsList;
