import React, { useState, useEffect, useRef } from 'react';
import queryString from 'query-string';

import MainMenu from './MainMenu';
import MealList from './MealsList';
import FoodIntake from './FoodIntake';
// import FoodIntakeEdit from './FoodIntakeEdit';
import FavoritProducts from './FavoritProducts';
import SearchProducts from './SearchProducts';
import SplashScreen from './SplashScreen';



const MainComponent = () => {

    const [data_edit, setDataEdit] = useState('');
    const [id_user, setIDUser] = useState('');
    const [isMainMenu, setIsMainMenu] = useState(false);
    const [isFavoriProducts, setIsFavoriProducts] = useState(false);
    const [isSearchProducts, setIsSearchProducts] = useState(false);
    const [isMeal, setIsMeal] = useState(false);
    const [isMealAdd, setIsMealAdd] = useState(false);
    const [isMealItemEdit, setIsMealItemEdit] = useState(false);
    const [isSplashScreen, setIsSplashScreen] = useState(true);


    useEffect(() => {
        const urlParams = queryString.parse(window.location.search);
        const id_user = urlParams.id_user;
        setIDUser(id_user);
        console.log("User ID:", id_user);
      }, []);
    
      useEffect(() => {
        const timer = setTimeout(() => {
          setIsSplashScreen(false);
          setIsMainMenu(true);
        }, 2000); // устанавливаем 1000 миллисекунд (1 секунду)
    
        return () => clearTimeout(timer); // очищаем таймер при анмонтировании компонента
      }, []); 

    

    
    const iconClick = (iconId, data_edit) => {
        console.log('iconId -->' + iconId);
        console.log('data_edit' , data_edit);
        setDataEdit(data_edit);
        setIsMainMenu(false);
        setIsFavoriProducts(false);
        setIsSearchProducts(false);
        setIsMeal(false);
        setIsMealAdd(false);
        setIsMealItemEdit(false);


        if (iconId == 'isFavoriProducts') {
            setIsFavoriProducts(true);
        }

        if (iconId == 'isMainMenu') {
            setIsMainMenu(true);
        }

        if (iconId == 'isSearchProducts') {
            setIsSearchProducts(true);
        }

        if (iconId == 'isMeal') {
            setIsMeal(true);
        }


        if (iconId == 'isMealAdd') {
            setIsMealAdd(true);
        }
        if (iconId == 'MealCansel') {
            setIsMeal(true);
        }

        if (iconId == 'MealItemEdit') {
            setIsMealItemEdit(true);

        }


      };
    

    return (
        isSplashScreen ? (
        <div>
           < SplashScreen />
        </div>
        ) :isMainMenu ? (
        <div>
            <MainMenu iconClick = {iconClick} />
        </div>

        ):isFavoriProducts ?(
            <div>
                {/* <SearchFavoritProductsComponent isFavoriProducts={isFavoriProducts} id_user = {id_user} iconMenuClick={iconClick}/> */}
                <FavoritProducts id_user = {id_user} iconMenuClick={iconClick}/>
            </div>
        ) : isSearchProducts? (
            <div>
                {/* <SearchFavoritProductsComponent isFavoriProducts={isFavoriProducts} id_user = {id_user} iconMenuClick={iconClick}/> */}
                <SearchProducts id_user = {id_user} iconMenuClick={iconClick}/>
            </div>

        ): isMeal ?(
            <div>
                <MealList id_user = {id_user} iconMenuClick={iconClick}/>
            </div>

        ): isMealAdd ?(
            <div><FoodIntake  id_user = {id_user} iconMenuClick={iconClick} isMealItemEdit={isMealItemEdit}/></div>

        ) : isMealItemEdit ? (
            
            <div><FoodIntake  id_user = {id_user} iconMenuClick={iconClick} dataEdit={data_edit} isMealItemEdit={isMealItemEdit}/></div>


        ) : (
            <div></div>
        )
        );

        
    };


export default MainComponent;

