import React, { useState, useEffect, useRef } from 'react';

import Info from './Info';
import queryString from 'query-string';

import { AiOutlineClose, AiOutlineStar, AiFillStar } from "react-icons/ai";
import TextField from '@mui/material/TextField';
import { IconButton , InputAdornment } from '@mui/material';
import { styled } from '@mui/system';
import { CiForkAndKnife } from "react-icons/ci";
import { CiSearch } from "react-icons/ci";
import { CiStar } from "react-icons/ci";
import { PiPersonSimpleRun } from "react-icons/pi";
import ButtonAddMeals from './Button/ButtonAddMeal';
import MainMenu from './MainMenu';
import Meal from './Meal';
import ModalInfo from './ModalInfo';
import MealsList from './MealsList';
import FoodList from './FoodList';
import { HiOutlineMenu } from "react-icons/hi";
import { HiOutlineX } from "react-icons/hi";
import { fetchDataFromDatabase } from './DataService';

let productID = [];


const extractMass = (string) => {
  const numbers = string.match(/\d+/g);
  const extractedNumbers = numbers ? numbers.join(', ') : 'Цифры не найдены';
  return extractedNumbers;
};
const sentence = (string) => {
  return `В 1г - ` + string + 'Ккал';
};
const ProductMassCal = (num1, num2) => {
if (num2 !=null) {
  const result = parseFloat(num1)*parseFloat(num2);
return 'В ' + num2 + 'г - '+ result.toFixed(2) + ' Ккал'
}
return ''
};
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const maincontainerStyles = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  color: 'black',
  paddingTop: '20px',
  height: '100%',
  // border: '1px solid #ccc'
};
const containerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowX: 'hidden',
  overflowY: 'hidden'
};
const informationStyles = {
  flex: 1,
  fontSize: '14px',
  paddingTop: '15px',
  // border: '1px solid #ccc'
  
};
const inputStyles = {
  flex: 1,
  padding: '10px 20px 5px',
  fontSize: '12px',
  borderRadius: '10px',
  marginLeft: '5px',
  marginRight: '5px',
};
const containerItem = {
  flex: 1,
  flexDirection: 'column',
  padding: '10px 20px 5px',
  marginBottom: '50px',
};
const itemStyle = {
  flex: 1,
  padding: '5px 0',
  alignSelf: 'flex-start',
  
};
const itemNameStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  // border: '1px solid #ccc'
};
const itemNameStyleText = {
  display: 'flex',
  flex: 1,
  fontSize: '14px',
  // fontWeight: 'bold',
  // border: '1px solid #ccc'
};
const itemNameStyleIcon = {
  display: 'flex',
  // border: '1px solid #ccc'
};
const itemCalorieStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontStyle: 'italic',
  // border: '1px solid #ccc'
};
const itemCalorieMStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '12px',
  fontStyle: 'italic',
  fontWeight: 'bold'
  // border: '1px solid #ccc'
};

const StyleFooter = {
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'center',
  justifyContent: 'space-around',
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  backgroundColor: 'white',
  // padding: '3px',
  border: '1px solid #ccc',
  
  // marginLeft: '1px'
};


const CustomTextField = styled(TextField)({
  '& .MuiFormHelperText-root': {
      color: 'red', // Применение красного цвета к тексту подсказки
      fontSize: '12px', // Изменение размера шрифта подсказки
      fontStyle: 'italic', // Применение курсива к тексту подсказки
      paddingTop: '5px',
      width: '100%',
  },
});
const CustomTextFieldFavorit = styled(TextField)({
  '& .MuiFormHelperText-root': {
      // color: 'red', // Применение красного цвета к тексту подсказки
      fontSize: '14px', // Изменение размера шрифта подсказки
      fontWeight: 'bold', // Применение курсива к тексту подсказки
      paddingTop: '20px',
      
  },
});




//+++++++++++++++++++

const ExampleComponent = () => {
  const [data, setData] = useState([])
  const [inputValue, setInputValue] = useState('');
  const [Mass, setMass] = useState(0);
  const [id_user, setIDUser] = useState('');
  const [starredItems, setStarredItems] = useState(data.map(() => false));
  
  const [isHelperText, sethelperText] = useState('Введите массу продукта/блюда в граммах');
  const [isPlaceholder, setPlaceholder] = useState('масса в граммах');
  const [isInformationText, setInformationText] = useState('Избранные продукты');
  const [isMealAdd, setMealAdd] = useState('+ Добавить прием пищи');

  const [isInfoIcon, setIsInfoIcon] = useState(true);
  const [isFavoritIcon, setIsFavoritIcon] = useState(false);
  const [isSearchIcon, setIsSearchIcon] = useState(false);
  const [isMealsIcon, setIsMealsIcon] = useState(false);
  const [isSportIcon, setIsSportIcon] = useState(false);
  const [isMenuIcon, setIsMenuIcon] = useState(false);

  const [isMealVisible, setIsMealVisible] = useState(false);
  const [isFoodListVisible, setIsFoodListVisible] = useState(false);

  const [mealData, setMealData] = useState(null);

  const [mealOpenId, setMealOpenId] = useState(null);
  const [mealOpenСhange, setMealOpenСhange] = useState(0);
  const [isOpenModalInfo, setIsOpenModalInfo] = useState(false);
  const [isOpenModalInfoText, setIsOpenModalInfoText] = useState('');



  

  

  
  useEffect(() => {

    
  }, [isInfoIcon]);


  useEffect(() => {
      setTimeout(() => {
        setIsOpenModalInfo(false);
      }, 1000); // Закрываем модальное окно через 3 секунды
  }, [isOpenModalInfo]);

  useEffect(() => {
    if (isFavoritIcon) { 
      if (isInfoIcon) { setIsInfoIcon(false) };
      if (isSearchIcon) { setIsSearchIcon(false) };
      if (isMealsIcon) { setIsMealsIcon(false) };
      if (isSportIcon) { setIsSportIcon(false) };
      if (isMenuIcon) { setIsMenuIcon(false) };
      if (isFoodListVisible) { setIsFoodListVisible(false) };

      fetchFavoriteProducts(id_user);
      setTexthelp('favorit');
     };
  }, [isFavoritIcon]);

  useEffect(() => {
    if (isSearchIcon) { 
      if (isInfoIcon) { setIsInfoIcon(false) };
      if (isFavoritIcon) { setIsFavoritIcon(false) };
      if (isMealsIcon) { setIsMealsIcon(false) };
      if (isSportIcon) { setIsSportIcon(false) };
      if (isMenuIcon) { setIsMenuIcon(false) };
      if (isFoodListVisible) { setIsFoodListVisible(false) };


      setData([]);
      setMass(0);
      setTexthelp('searchProduct');
     };
  }, [isSearchIcon]);

  useEffect(() => {
    if (isMealsIcon) { 
      if (isInfoIcon) { setIsInfoIcon(false) };
      if (isFavoritIcon) { setIsFavoritIcon(false) };
      if (isSearchIcon) { setIsSearchIcon(false) };
      if (isSportIcon) { setIsSportIcon(false) };
      if (isMenuIcon) { setIsMenuIcon(false) };
      if (isFoodListVisible) { setIsFoodListVisible(false) };
     } else {

      // if (isInfoIcon) { setIsInfoIcon(false) };
      if (!isFavoritIcon && !isInfoIcon) { setIsFavoritIcon(true) };
      // if (isSearchIcon) { setIsSearchIcon(false) };
      // if (isSportIcon) { setIsSportIcon(false) };
      // if (isMenuIcon) { setIsMenuIcon(false) };
      // if (isFoodListVisible) { setIsFoodListVisible(false) };
      console.log('Да!')
      console.log({isInfoIcon});
      console.log({isFavoritIcon});
      console.log({isSearchIcon});



     }


  }, [isMealsIcon]);

  useEffect(() => {
    if (isSportIcon) { 
      if (isInfoIcon) { setIsInfoIcon(false) };
      if (isFavoritIcon) { setIsFavoritIcon(false) };
      if (isSearchIcon) { setIsSearchIcon(false) };
      if (isMealsIcon) { setIsMealsIcon(false) };
      if (isMenuIcon) { setIsMenuIcon(false) };
      if (isFoodListVisible) { setIsFoodListVisible(false) };
     };
  }, [isSportIcon]);

  useEffect(() => {
    if (isMenuIcon) { 
      if (isInfoIcon) { setIsInfoIcon(false) };
      if (isFavoritIcon) { setIsFavoritIcon(false) };
      if (isSearchIcon) { setIsSearchIcon(false) };
      if (isMealsIcon) { setIsMealsIcon(false) };
      if (isSportIcon) { setIsSportIcon(false) };
      if (isFoodListVisible) { setIsFoodListVisible(false) };
     } else {
      // 
      console.log('Ура!');


      if (isFavoritIcon) { setIsFavoritIcon(false) };
      if (!isSearchIcon && !isInfoIcon) { setIsSearchIcon(true) };
      if (isMealsIcon) { setIsMealsIcon(false) };
      if (isSportIcon) { setIsSportIcon(false) };
      if (isFoodListVisible) { setIsFoodListVisible(false) };

      console.log({isInfoIcon});
      console.log({isFavoritIcon});
      console.log({isSearchIcon});

     }
  }, [isMenuIcon]);


  useEffect(() => {
    if (isFoodListVisible) { 
      if (isInfoIcon) { setIsInfoIcon(false) };
      if (isFavoritIcon) { setIsFavoritIcon(false) };
      if (isSearchIcon) { setIsSearchIcon(false) };
      if (isMealsIcon) { setIsMealsIcon(false) };
      if (isSportIcon) { setIsSportIcon(false) };
      if (isMenuIcon) { setIsMenuIcon(false) };

      // setData([]);
      // setMass();
      // setTexthelp('searchProduct');
     };
  }, [isFoodListVisible]);

  // useEffect(() => {
  //   console.log(mealOpenId);
  // }, [mealOpenId]);

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch('https://www.foodandsports.ru/api/favoritesproductsgetalluserid.php', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ id_user: id_user }),
      });
      
      const favoriteProductsData = await response.json();
      const productID = favoriteProductsData.map(product => product.id_productfood);
      const newStarredItems = data.map(item => productID.includes(item.id));
      setStarredItems(newStarredItems);

      console.log(productID); // Новые данные
      // console.log(favoriteProductsData.map(item => item.id)); // Выводим id каждого элемента
           
    } catch (error) {
      console.error(error);
  }
  };

  fetchData(); // Вызываем функцию fetchData

}, [data]);

useEffect(() => {
  const urlParams = queryString.parse(window.location.search);
  const id_user = urlParams.id_user;
  setIDUser(id_user);
  console.log("User ID:", id_user);
}, []);

const setTexthelp = (string) => {
  setInputValue('');

  if (string === 'searchProduct') {
    setInformationText('Найденные продукты');
    setPlaceholder('продукт, масса в граммах');
    sethelperText('Введите для поиска название продукта/блюда и массу. Пример: Яблоко 50');
  } 
  if (string === 'favorit') {
    setInformationText('Избранные продукты');
    setPlaceholder('масса в граммах');
    sethelperText('Введите массу продукта/блюда в граммах');
  }    
};

// Функция для получения информации о фаворитных продуктах пользователя и их добавления в starredProducts
const fetchFavoriteProducts = async (id_user) => {
  console.log('Ура! - fetchFavoriteProducts');
  try {
    const response = await fetch('https://www.foodandsports.ru/api/favoritesproductsgetalluser.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_user: id_user }),
    });

    const favoriteProductsData = await response.json();
    // console.log(favoriteProductsData);
    // console.log(data);
    
    const initialStarredItems = favoriteProductsData.map(product => ({
      isStarred: true, // Продукт изначально помечается как избранный
    }));
    
    // console.log(state);
    setMass(0);
    setData(favoriteProductsData);
    setStarredItems(initialStarredItems);

} catch (error) {
    console.error(error);
}

};


const handleStarClick = async (index) => {
const newStarredItems = [...starredItems];
newStarredItems[index] = !newStarredItems[index];
setStarredItems(newStarredItems);

    // Отправка данных на сервер через API
    try {
      if (newStarredItems[index]) { // Добавление продукта в избранные
        const response = await fetch('https://www.foodandsports.ru/api/favoritesproductsadd.php', {
          method: 'POST',
            headers: {
              'Content-Type': 'application/json',     
            },
            body: JSON.stringify({ id_food: data[index].id, id_user: id_user}),
        });
        console.log({ id_food: data[index].id, id_user: id_user });
      }

      if (!newStarredItems[index]) { // Удаление продукта в избранного
        console.log("555");
        await fetch(`https://www.foodandsports.ru/api/favoritesproductsdel.php`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',     
            },
            body: JSON.stringify({ id_food: data[index].id, id_user: id_user}),
    
        });
        console.log("111");
        console.log({ id_food: data[index].id, id_user: id_user });
        
        if (isFavoritIcon) {
          fetchFavoriteProducts (id_user);
          console.log("222")
        }
     }

    } catch (error) {
        console.error('Произошла ошибка:', error);
    }  
};

  const handleInputChange = async (event) => {
    const value = event.target.value;
    
    setInputValue(value);
    setMass(0);

    if (!isFavoritIcon) { //Если иконка звездочки светлая

      
    if (value === '') {
      clearInput();
      return;
    }

  const valueText = value.replace(/\d/g, ''); // замена цифр на пустую строку
  
  if (extractMass(value) != 'Цифры не найдены') {
    setMass(extractMass(value))
  }
  
    const url = `https://www.foodandsports.ru/api/search_product.php?term=${valueText}`;

    try {
      const response = await fetch(url);
      const jsonData = await response.json();
      console.log('Ура2');

      const response1 = await fetch('https://www.foodandsports.ru/api/favoritesproductsgetalluser.php', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id_user: id_user }),
    });

    const favoriteProductsData = await response1.json();  
    console.log(favoriteProductsData );
    

    const productID = favoriteProductsData.map(product => product.id_productfood);
    const newStarredItems = data.map(item => productID.includes(item.id));
    console.log(data );
    setStarredItems(newStarredItems);
      
    setData(jsonData);
    
      

    } catch (error) {
      console.error(error);
    }
    } else { //Если иконка звездочки темная
      console.log('Темная звездочка');
      const valueText = value.replace(/\D/g, ''); //удаление букв
      
      if (extractMass(value) == 'Цифры не найдены') {
        setMass(0);
      } else {
        setMass(extractMass(valueText))
      }
    }
  };

  const clearInput = () => {
      setInputValue('');
      setMass(0);

    if (!isFavoritIcon) { //Если иконка звездочки светлая
      setData([]);
      
    } else { //Если иконка звездочки темная
      setInputValue('');
      fetchFavoriteProducts(id_user);
    }
  };

  const handleIconClick = () => {
    setIsFavoritIcon(true);
};

const FoodListClick = (products) => {
  console.log('Ура 3!');
  console.log([products]);
  // setIsMealVisible(false);
  setIsFoodListVisible(true);
 
};

const SerchFoodClick = () => {
  console.log('Ура 4!');
  setIsSearchIcon(true);
};


const handleItemClick = async (index) => {
  if (mealOpenId !== null){

    console.log('Item clicked:', data[index]);
    setMealData(data[index]); // Обновляем данные компонента Meal
    
    const url = 'https://www.foodandsports.ru/api/meallistaddproductfood.php';
    
    console.log(mealOpenId);
    console.log(data[index].id);
    console.log(Mass);
    
    setIsOpenModalInfo(true);
    if (Mass == 0) {
       setIsOpenModalInfoText('Введите массу продукта/блюда');
       
    } else {
      setMealOpenСhange(prevId => prevId + 1);
      setIsOpenModalInfoText('Продукт/блюдо добавлено в прием пищи');
      const jsonData = JSON.stringify({ id_meal: mealOpenId, id_productfood :data[index].id, massa : Mass});
      await fetchDataFromDatabase(url, jsonData);
      
    }
    
  }

};

const ClickDelMeal = async () => {
  if (mealOpenId === null) {
    console.error('mealOpenId не определен');
    return;
  }
  setIsMealVisible(false);
  setMealOpenId(null);
  setMealOpenСhange(0);

  const url = 'https://www.foodandsports.ru/api/usermealdelid.php';
  const jsonData = JSON.stringify({ id: mealOpenId});
  await fetchDataFromDatabase(url, jsonData);

  const urlOne = 'https://www.foodandsports.ru/api/meallistdelid.php';
  const jsonDataOne = JSON.stringify({ id_meal: mealOpenId});
  console.log(mealOpenId);
  await fetchDataFromDatabase(urlOne, jsonDataOne);

};

const userMealAdd = async () => {
  setIsMealVisible(true);

  const currentDate = new Date();
  const hours = currentDate.getHours().toString().padStart(2, '0'); // Получаем часы
  const minutes = currentDate.getMinutes().toString().padStart(2, '0'); // Получаем минуты
  const seconds = currentDate.getSeconds().toString().padStart(2, '0'); // Получаем секунды
  const timeString = `${hours}:${minutes}:${seconds}`;

  const url = 'https://www.foodandsports.ru/api/usermealadd.php';
  const jsonData = JSON.stringify({ id_user: id_user, date: currentDate, time: timeString });
  const m = await fetchDataFromDatabase(url, jsonData);
  setMealOpenId(m.id);

};

const ExitlMeal = async () => {
  setIsMealVisible(false);
  setMealOpenId(null);
  setMealOpenСhange(0);
}

const MealsListBack = async () => {
  // setIsMealVisible(false);
  // setMealOpenId(null);
  // setMealOpenСhange(0);

  setIsMealsIcon(false);
}



  return (

    <div style={maincontainerStyles}>
     
    {isInfoIcon ? (
    <div>
      <Info handleIconClick={handleIconClick}/>
    </div>
  ) : isFavoritIcon || isSearchIcon ? (
  <div >
          <div style={containerStyles}> 

       <CustomTextField
            style={inputStyles} 
            value={inputValue} 
            helperText={isHelperText}
            onChange={handleInputChange} 
            placeholder={isPlaceholder}
            InputProps={{
                endAdornment: (
                  <React.Fragment>
                      <IconButton onClick={clearInput}>
                        <HiOutlineX />
                      </IconButton>

                      <IconButton onClick={() => setIsFavoritIcon(true)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                       {isFavoritIcon ? <CiStar style={{ color: '#2E9CCA' }} /> : <CiStar />}
                      </IconButton> 
                     
                  </React.Fragment>  
                )
            }}
        />
        
      </div>
     


      <div>{!isMealVisible && <ButtonAddMeals text="Добавить прием пищи" onClick={userMealAdd}/>}
      {isMealVisible && <Meal ClickDelMeal = {ClickDelMeal} FoodListClick={FoodListClick} ExitlMeal={ExitlMeal} mealOpenId={mealOpenId} mealOpenСhange={mealOpenСhange}/>}
      </div>
      <div> {isOpenModalInfo && <ModalInfo text ={isOpenModalInfoText}/>}</div>
      {/* <div>{!isMealVisible && <ButtonAddMeals text="Создать свое блюдо" />}
      {isMealVisible && <Meal ClickDelMeal = {ClickDelMeal} />}
      </div> */}

      <div style={informationStyles}>{isInformationText}</div>
      <ul style={containerItem}>
        {data.map((item, index) => (
         <div key={index} style={itemStyle} onClick={() => handleItemClick(index)}>
          <div style={itemNameStyle}>
            <div style={itemNameStyleText}>
              {capitalizeFirstLetter(item.name)}
            </div>
            <div style={itemNameStyleIcon} onClick={(e) => {
              e.stopPropagation(); // Остановить всплытие события
                 if (!e.target.closest('.itemNameStyleIcon')) {
                   handleStarClick(index);
                    }
             }}>
           
            {starredItems[index] ? <AiFillStar /> : <AiOutlineStar />}
            
            </div>   
          </div>
          <div style={itemCalorieStyle} >
            <div>{sentence(item.calorie)}</div>
          </div>
          <div style={itemCalorieMStyle}>
            <div>{Mass !== 0 && ProductMassCal(item.calorie, Mass)}</div>
          </div>
         </div>
        ))}
      </ul>

     
     <div style={StyleFooter}> 

     

{/* <IconButton onClick={() => setIsFavoritIcon(true)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {isFavoritIcon ? <CiStar style={{ color: '#2E9CCA' }} /> : <CiStar />}
  <span style={{ marginTop: '3px', fontSize: '8px' , color: isFavoritIcon  ? '#2E9CCA': 'black' }}>Избранные продукты</span>
</IconButton>  */}

<IconButton onClick={() => setIsSearchIcon(true)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {isSearchIcon ? <CiSearch style={{ color: '#2E9CCA' }}  /> : <CiSearch />}
  <span style={{ marginTop: '3px', fontSize: '8px', color: isSearchIcon  ?  '#2E9CCA': 'black'}}>Продукты/блюда</span>
</IconButton>  

<IconButton onClick={() => setIsMealsIcon(true)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {isMealsIcon ? <CiForkAndKnife   style={{ color: '#2E9CCA' }} /> : <CiForkAndKnife    />}
  <span style={{ marginTop: '3px', fontSize: '8px', color: isMealsIcon  ?  '#2E9CCA': 'black' }}>Приемы пищи</span>
</IconButton>  

<IconButton onClick={() => setIsSportIcon(true)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {isSportIcon ? <PiPersonSimpleRun   style={{ color: '#2E9CCA' }} /> : <PiPersonSimpleRun   />}
  <span style={{ marginTop: '3px', fontSize: '8px' , color: isSportIcon ? '#2E9CCA': 'black' }}>Спорт</span>
</IconButton>  

<IconButton onClick={() => setIsMenuIcon(!isMenuIcon)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {isMenuIcon ? <HiOutlineMenu    style={{ color: '#2E9CCA' }} /> : <HiOutlineMenu    />}
  <span style={{ marginTop: '3px', fontSize: '8px' , color: isMenuIcon ? '#2E9CCA': 'black' }}>Меню</span>
  
</IconButton>  


</div>
      
     
  </div>
) : isMealsIcon ? (
  <div>
    <MealsList  id_user={id_user} MealsListBack={MealsListBack}/>
  </div>
) : isSportIcon ? (
  <div>
    {/* Contеnt for div 4 */}
  </div>
) : isMenuIcon ?(
  <div>
    <MainMenu />
  </div>
) : isFoodListVisible ?(
  <div>
    <FoodList SerchFoodClick={SerchFoodClick}/>
    {/* Contеnt for div 4 */}
  </div>
) : (
  <div></div>
)
}

    </div>
    
  );
};

export default ExampleComponent;





