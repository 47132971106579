import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Caloriefoods from './components/Caloriefoods';

function Router() {
      return (
      <BrowserRouter>
       <div className="App">
            <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="caloriefoods" element={<Caloriefoods />} />
            </Routes>
      </div>
    </BrowserRouter>
      )
}
const Home = () => {
      return <h1>Home Page</h1>;
    };

export default Router;