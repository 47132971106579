import React from 'react';
import './FloatingButtonBackArrow.css';
import { ReactComponent as BackArrow } from '../myicon/left_icon.svg';


const FloatingButtonBackArrow = ({onClick}) => {
    return (
        <button className="floating-button" onClick={onClick} >
         < BackArrow />
        </button>
    );
};
export default FloatingButtonBackArrow;